import React from "react";
import { Routes, Route } from "react-router-dom";
import ThreeJsCanvas from "./components/threejs_canvas";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className={"common-container anatomy-container col-md-12"}>
            <ThreeJsCanvas></ThreeJsCanvas>
          </div>
        }
      />
      <Route
        path="/nerves"
        element={
          <div className={"common-container anatomy-container col-md-12"}></div>
        }
      />
    </Routes>
  );
}

export default App;
